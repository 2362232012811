@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300&display=swap");

* {
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100svh;
}

main {
  min-height: 90svh;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

p {
  margin: 0px;
  padding: 0px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

h3 {
  margin: 0px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  padding: 1em 1em;
}

.cards {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.trail__img {
  width: 20em;
  height: 14em;
  object-fit: cover;
  border-radius: 15px;
}

.trail-card {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: rgb(27, 49, 15);
  margin: 0.5em;
}

.trail-card--top-line {
  margin-top: 0.5em;
}

#star {
  color: rgb(44, 214, 44);
}

a {
  text-decoration: none;
}

a:visited {
  color: inherit;
  text-decoration: none;
}

#navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 7svh;
}

.logo {
  padding-right: 10px;
}

.logo-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 3em;
  height: 1.75em;
  padding: 0 10px;
}

.nav-btn {
  cursor: pointer;
  font-weight: bolder;
  border: 0px black solid;
  background-color: transparent;
  color: rgb(27, 49, 15);
  padding: 0 10px;
}

input {
  font-family: "Manrope", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3px;
  margin: 5px;
  width: 100%;
}

textarea {
  font-family: "Manrope", sans-serif;
}

form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.75em;
  color: rgb(27, 49, 15);
}

label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-btn {
  background: rgba(0, 81, 255, 0.77);
  color: white;
  padding: 0.5em 1.25em;
  margin: 0.5em;
  border: 1px solid black;
  border-radius: 3em;
  cursor: pointer;
  box-shadow: 1.5px 1.5px 1px rgb(173, 190, 195);
}

.login-btn:visited {
  color: white;
}

.login-btn:hover {
  opacity: 0.8;
  transition-duration: 0.5s;
}

#header-photo {
  height: 320px;
  object-fit: cover;
  width: 100%;
}

#trail-header-photo {
  margin: 1em;
  max-height: 320px;
  width: 80%;
  max-width: 100%;
  object-fit: cover;
  border-radius: 2em;
}

.header-container {
  position: relative;
  text-align: center;
  color: white;
}

.header-text {
  font-size: 3rem;
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.trail-header-text {
  font-size: 3rem;
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.grey {
  color: grey;
}

.errors {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  color: red;
}

span.detail {
  padding: 20px;
}

.reviews {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.review {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 18em;
  border: 2px solid #5f5f5f;
  border-radius: 1.5em;
  padding: 0.75em;
  margin: 1em;
  text-wrap: wrap;
}

.review h4 {
  margin: 0px;
  padding: 2px;
}

.review p {
  text-align: center;
}

.profile-photo {
  margin-top: 10px;
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
}

.review-edit-form {
  min-height: 90svh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-text-box {
  height: 300px;
  max-width: 500px;
}

.green-btn {
  border-radius: 30px;
  width: 130px;
  height: 50px;
  background-color: darkgreen;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition-duration: 0.5s;
  margin-top: 10px;
  margin-bottom: 10px;
}

.green-btn:hover {
  background-color: rgb(23, 71, 23);
  transition-duration: 0.5s;
}

.trail-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1em;
}

.trail-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #5f5f5f;
  flex-wrap: wrap;
  padding: 0.25em;
  margin: 1em;
}

.trail-page-container p {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.trail-page-container h4 {
  display: flex;
  justify-content: center;
}

.trail-map-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  word-wrap: break-word;
  max-width: 800px;
  margin: 1em;
  gap: 1em;
}

.coordinates {
  margin: 1em;
}

.user-thumbnail {
  min-width: 7em;
  max-height: 7em;
  object-fit: contain;
}

.profile-container {
  margin-top: 20px;
  max-width: 600px;
  text-align: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.border-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid black;
  border-radius: 1em;
  padding: 0.75em;
  max-width: 18em;
  margin: 1em auto;
}

.select {
  margin: 0.5em;
}

.review-thumbnail {
  min-width: 7em;
  max-height: 7em;
}

.review-title {
  margin: 5px;
}

.wrapper {
  min-height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.a {
  color: #282c34;
}

.iframe-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

iframe {
  width: 100%;
  min-height: 400px;
  margin: 1em;
  border: 1px solid black;
}

.center-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-row p {
  padding: 5px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.create-review-container {
  margin: 1em;
  height: 90svh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.footer__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  min-height: 6svh;
  margin: 1em auto;
}

.footer--left,
.footer--right {
  margin: 0 2em;
}

footer {
  border-top: 2px solid #5f5f5f;
  margin-top: 2em;
}

@media (max-width: 500px) {
  .trail-header-text {
    font-size: 2rem;
  }

  .nav-btn {
    font-size: 0.9rem;
  }

  .logo {
    font-size: 0.9rem;
  }
}
